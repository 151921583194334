var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { EXCHANGE_VARIABLES } from '../components/constants/EXCHANGE_VARIABLES';
import { LAYERMODELHASURA_MAP } from '../components/constants/LAYERMODELHASURA_MAP';
import { LAYER_DATA, getLayerData, getLayerProps } from '../components/constants/LAYER_DATA';
import { OPERATOR_FILTER_MAP } from '../components/constants/OPERATOR_FILTER_MAP';
import { configureLayer, getCompatibleExtendedLayerConfigs } from '../services/ConfigitService';
import * as LayerApi from '../utils/LayerApi';
export var AllowMoreLayersAboveTop = function (arraySelectedLayers, arrayConfigStrings) { if (arrayConfigStrings.length === 0)
    return false; if (arraySelectedLayers.length === 0)
    return true; var firstLayer = arraySelectedLayers[0]; for (var i = 0; i < arrayConfigStrings.length; i++) {
    if (!arrayConfigStrings[i].startsWith(firstLayer))
        return true;
} return false; };
export var AllowMoreLayersBelowBottom = function (arraySelectedLayers, arrayConfigStrings) { if (arrayConfigStrings.length === 0)
    return false; if (arraySelectedLayers.length === 0)
    return true; var lastLayer = arraySelectedLayers[arraySelectedLayers.length - 1]; for (var i = 0; i < arrayConfigStrings.length; i++) {
    if (!arrayConfigStrings[i].endsWith(lastLayer))
        return true;
} return false; };
export var AllowMoreLayersBetweenLayers = function (arraySelectedLayers, arrayConfigStrings, upLayerIndex, downLayerIndex) { if (arrayConfigStrings.length === 0)
    return false; if (arraySelectedLayers.length === 0)
    return true; var upLayer = arraySelectedLayers[upLayerIndex]; var downLayer = arraySelectedLayers[downLayerIndex]; var twoLayersTogether = upLayer + ',' + downLayer; for (var i = 0; i < arrayConfigStrings.length; i++) {
    if (arrayConfigStrings[i].indexOf(twoLayersTogether) === -1)
        return true;
} return false; };
export var LayerTypesAllowedAboveTop = function (arraySelectedLayers, arrayConfigStrings) { if (arrayConfigStrings.length === 0)
    return []; if (arraySelectedLayers.length === 0)
    return GetAllLayersNoDuplicates(arrayConfigStrings); var allowedLayers = []; var firstLayer = arraySelectedLayers[0]; for (var i = 0; i < arrayConfigStrings.length; i++) {
    var indexFirstLayer = arrayConfigStrings[i].indexOf(firstLayer);
    if (indexFirstLayer > 0) {
        var layers = arrayConfigStrings[i].substring(0, indexFirstLayer - 1).split(',');
        allowedLayers = __spreadArray(__spreadArray([], allowedLayers, true), layers, true);
    }
} var allowedLayersNoDuplicates = Array.from(new Set(allowedLayers)); return allowedLayersNoDuplicates; };
export var LayerTypesAllowedBelowBottom = function (arraySelectedLayers, arrayConfigStrings) { if (arrayConfigStrings.length === 0)
    return []; if (arraySelectedLayers.length === 0)
    return GetAllLayersNoDuplicates(arrayConfigStrings); var allowedLayers = []; var lastLayer = arraySelectedLayers[arraySelectedLayers.length - 1]; for (var i = 0; i < arrayConfigStrings.length; i++) {
    var indexLastLayer = arrayConfigStrings[i].indexOf(lastLayer);
    if (indexLastLayer < arrayConfigStrings[i].length - 2) {
        var layers = arrayConfigStrings[i].substring(indexLastLayer + 3).split(',');
        allowedLayers = __spreadArray(__spreadArray([], allowedLayers, true), layers, true);
    }
} var allowedLayersNoDuplicates = Array.from(new Set(allowedLayers)); return allowedLayersNoDuplicates; };
export var LayerTypesAllowedBetweenLayers = function (arraySelectedLayers, arrayConfigStrings, upLayerIndex, downLayerIndex) { if (arrayConfigStrings.length === 0)
    return []; if (arraySelectedLayers.length === 0)
    return GetAllLayersNoDuplicates(arrayConfigStrings); var allowedLayers = []; var upLayer = arraySelectedLayers[upLayerIndex]; var downLayer = arraySelectedLayers[downLayerIndex]; for (var i = 0; i < arrayConfigStrings.length; i++) {
    var indexUpLayer = arrayConfigStrings[i].indexOf(upLayer);
    var indexDownLayer = arrayConfigStrings[i].indexOf(downLayer, indexUpLayer + 1);
    if (indexUpLayer > 0 && indexDownLayer > indexUpLayer) {
        var layers = arrayConfigStrings[i].substring(indexUpLayer + 3, indexDownLayer - 1).split(',');
        allowedLayers = __spreadArray(__spreadArray([], allowedLayers, true), layers, true);
    }
} var allowedLayersNoDuplicates = Array.from(new Set(allowedLayers)); return allowedLayersNoDuplicates; };
export var GetAllLayersNoDuplicates = function (arrayConfigStrings) { var allowedLayers = []; for (var i = 0; i < arrayConfigStrings.length; i++) {
    var layers = arrayConfigStrings[i].split(',');
    allowedLayers = __spreadArray(__spreadArray([], allowedLayers, true), layers, true);
} var allowedLayersNoDuplicates = Array.from(new Set(allowedLayers)); return allowedLayersNoDuplicates; };
export var insertLayer = function (layers, layer, addLayerAt) { return layers !== null && layers !== void 0 && layers.length ? __spreadArray(__spreadArray(__spreadArray([], layers.slice(0, addLayerAt), true), [String(layer)], false), layers.slice(addLayerAt), true) : [String(layer)]; };
var matchingLayerConfigUtil = function (layers, excludedLayers, layerConfigs, singleMatch, configObjs) { var matchingConfigIds = []; var matchingConfigs = []; var layerRegex = new RegExp("^.*".concat(layers.join(',.*'), ".*$")); var excludedLayerRegex = new RegExp("^.*(".concat(excludedLayers.join('|'), ").*$")); for (var i = 0; i < layerConfigs.length; i++) {
    var allowedValue = layerConfigs[i];
    var hasLayers = layers.length ? layerRegex.test(String(allowedValue.name)) : true;
    var hasExcludedLayers = excludedLayers.length ? excludedLayerRegex.test(String(allowedValue.name)) : false;
    if (hasLayers && !hasExcludedLayers) {
        if (configObjs)
            matchingConfigs.push(layerConfigs[i]);
        else
            matchingConfigIds.push(String(allowedValue.value));
        if (singleMatch)
            break;
    }
} if (configObjs)
    return matchingConfigs; return matchingConfigIds; };
export var getMatchingLayerConfigIds = function (layers, excludedLayers, layerConfigs) { var matchingConfigIds = matchingLayerConfigUtil(layers, excludedLayers, layerConfigs); return matchingConfigIds; };
export var getMatchingLayerConfigs = function (layers, excludedLayers, layerConfigs) { var matchingConfigIds = matchingLayerConfigUtil(layers, excludedLayers, layerConfigs, false, true); return matchingConfigIds; };
export var isLayeConfigMatching = function (layers, excludedLayers, layerConfigs) { var matchingConfigIds = matchingLayerConfigUtil(layers, excludedLayers, layerConfigs, true); return matchingConfigIds.length ? true : false; };
var isAllLayerConfigsMatching = function (pattern, layerConfigs) { for (var i = 0; i < layerConfigs.length; i++) {
    if (!pattern.test(String(layerConfigs[i])))
        return false;
} return true; };
var getAutoSelectedLayersBFS = function (sampleLayers, layerConfigs, layerRegex) { var level = sampleLayers.length; var queue = []; queue.push(sampleLayers); var autoSelectedLayers = {}; while (queue.length) {
    var curSampleLayers = queue.shift();
    if ((curSampleLayers === null || curSampleLayers === void 0 ? void 0 : curSampleLayers.length) !== undefined) {
        if (curSampleLayers.length < level) {
            var autoSelectedLayerKeys = Object.keys(autoSelectedLayers);
            if (autoSelectedLayerKeys.length === 1)
                return autoSelectedLayers[autoSelectedLayerKeys[0]];
            if (autoSelectedLayerKeys.length > 1) {
                return getAutoSelectedLayersBFS(autoSelectedLayers[autoSelectedLayerKeys[0]], autoSelectedLayerKeys.map(function (data) { return autoSelectedLayers[data].join(','); }), layerRegex);
            }
            autoSelectedLayers = {};
            level = curSampleLayers.length;
            if (level === 0)
                return [];
        }
        var sampleLayersRegexStr = "^.*".concat(curSampleLayers.join(',.*'), ".*$");
        var sampleLayersRegex = new RegExp(sampleLayersRegexStr);
        var match = true;
        if (layerRegex && !isAllLayerConfigsMatching(layerRegex, [curSampleLayers.join(',')]))
            match = false;
        if (!isAllLayerConfigsMatching(sampleLayersRegex, layerConfigs))
            match = false;
        if (match)
            autoSelectedLayers[sampleLayersRegexStr] = curSampleLayers;
        var _loop_1 = function (i) {
            var nextSampleLayers = curSampleLayers.filter(function (layer, index) { return index !== i; });
            var alreadyAdded = queue.find(function (data) { return data.join(',') === nextSampleLayers.join(','); });
            if (!alreadyAdded)
                queue.push(nextSampleLayers);
        };
        for (var i = 0; i < curSampleLayers.length; i++) {
            _loop_1(i);
        }
    }
} return []; };
var getSmallestLayerConfigs = function (layerConfigs) { var size = layerConfigs[0].length; for (var i = 0; i < layerConfigs.length; i++) {
    var temp = layerConfigs[i];
    if (size && temp !== null && temp !== void 0 && temp.length && (temp === null || temp === void 0 ? void 0 : temp.length) < size)
        size = temp === null || temp === void 0 ? void 0 : temp.length;
} var result = []; for (var i = 0; i < layerConfigs.length; i++) {
    var temp = layerConfigs[i];
    if (size && (temp === null || temp === void 0 ? void 0 : temp.length) === size)
        result.push(temp);
} return result; };
export var getAutoSelectedLayers = function (layerConfigs, userSelectedLayers) { if (!layerConfigs.length)
    return []; var smallestLayerConfigs = getSmallestLayerConfigs(layerConfigs); var sampleLayers = smallestLayerConfigs[0].split(','); return getAutoSelectedLayersBFS(sampleLayers, layerConfigs, userSelectedLayers !== null && userSelectedLayers !== void 0 && userSelectedLayers.length ? new RegExp("^.*".concat(userSelectedLayers.join(',.*'), ".*$")) : undefined); };
export var getLayerDataById = function (id) { var layerData; for (var i = 0; i < LAYER_DATA.length; i++) {
    layerData = LAYER_DATA[i].layers.find(function (data) { return data.value === id; });
    if (layerData)
        break;
} return layerData; };
export var getLayersToExclude = function (selectedLayers, excludedLayers) { return getLayerData().map(function (layerType) { return layerType.layers.map(function (layer) { layer.incompatible = selectedLayers.indexOf(String(layer.value)) > -1; layer.assigned = excludedLayers.indexOf(String(layer.value)) > -1 ? 'byUser' : undefined; return layer; }); }).flat(1); };
export var updateLayerFilter = function (layers, filters) { filters._and = []; layers.forEach(function (layer) { var _layer$variableAssign; var layerData = getLayerDataById(layer.id); var layerProps = getLayerProps(layerData); var model = layerProps.model ? layerProps.model : '--NA--'; var view = LAYERMODELHASURA_MAP[model]; if (!view)
    return; var filter = {}; filter[view] = {}; var filterApplied = false; (_layer$variableAssign = layer.variableAssignments) === null || _layer$variableAssign === void 0 ? void 0 : _layer$variableAssign.forEach(function (data) { var _layer$section, _layer$section$variab; var variable = (_layer$section = layer.section) === null || _layer$section === void 0 ? void 0 : (_layer$section$variab = _layer$section.variables) === null || _layer$section$variab === void 0 ? void 0 : _layer$section$variab.find(function (variable) { return variable.id === data.variableId; }); if (variable && variable.id) {
    var _data$allowedValues;
    var type = variable.properties && variable.properties[0] && variable.properties[0].value;
    if (variable.id === 'ManufacturerId' || variable.id === 'ComponentId' || variable.id === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId' || EXCHANGE_VARIABLES.includes("".concat(variable.id, "_view")) || variable.id.endsWith('Operator'))
        return;
    if (variable.id === 'ComponentId_view')
        filter[view]['ComponentId'] = { _in: (_data$allowedValues = data.allowedValues) === null || _data$allowedValues === void 0 ? void 0 : _data$allowedValues.map(function (obj) { return parseInt(String(obj.value)); }) };
    else if (type === 'enum' || type === 'codetable')
        filter[view][variable.id] = { _eq: parseInt(String(data.value)) };
    else if (type === 'decimal')
        filter[view][variable.id] = { _eq: parseFloat(String(data.value)) };
    else if (type === 'measurement') {
        var _layer$variableAssign2, _layer$variableAssign3;
        var operator = (_layer$variableAssign2 = layer.variableAssignments) === null || _layer$variableAssign2 === void 0 ? void 0 : (_layer$variableAssign3 = _layer$variableAssign2.find(function (data) { return data.variableId === "".concat(variable.id, "Operator"); })) === null || _layer$variableAssign3 === void 0 ? void 0 : _layer$variableAssign3.value;
        if (!operator)
            return;
        filter[view][variable.id] = {};
        var PRECISIONTOLERANCE = 0.0001;
        switch (String(operator)) {
            case 'Equal':
                filter[view][variable.id]['_gte'] = parseFloat(String(data.value)) - PRECISIONTOLERANCE;
                filter[view][variable.id]['_lte'] = parseFloat(String(data.value)) + PRECISIONTOLERANCE;
                break;
            case 'BiggerOrEqual':
                filter[view][variable.id][OPERATOR_FILTER_MAP[String(operator)]] = parseFloat(String(data.value)) - PRECISIONTOLERANCE;
                break;
            case 'LessOrEqual':
                filter[view][variable.id][OPERATOR_FILTER_MAP[String(operator)]] = parseFloat(String(data.value)) + PRECISIONTOLERANCE;
                break;
            default: filter[view][variable.id][OPERATOR_FILTER_MAP[String(operator)]] = parseFloat(String(data.value));
        }
    }
    else if (type === 'boolean')
        filter[view][variable.id] = { _eq: Boolean(String(data.value)) };
    else
        filter[view][variable.id] = { _eq: data.value };
    filterApplied = true;
} }); if (filterApplied)
    filters._and.push(filter); }); return filters; };
export var updateExchangeVariableAssignments = function (layers, excludedLayerIds, variableAssignments, sections, allExtendedLayerConfigs) { return __awaiter(void 0, void 0, void 0, function () {
    var compatibleExtendedLayerConfigs, matchingExtendedLayerConfigs, newVariableAssignments, _loop_2, _layer$section2, _layer$section2$varia, _layer$section2$varia2, _layer$section2$varia3, _variableAssignment$a, i, relativeSeqNum, layerTypeIdVA, exchangeVariableAssignments;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                compatibleExtendedLayerConfigs = getCompatibleExtendedLayerConfigs(sections, allExtendedLayerConfigs);
                matchingExtendedLayerConfigs = LayerApi.getMatchingLayerConfigs(layers.filter(function (data) { return !data.autoSelected; }), excludedLayerIds, compatibleExtendedLayerConfigs);
                newVariableAssignments = __spreadArray(__spreadArray([], variableAssignments.filter(function (data) { return data.value; }), true), [{ variableId: 'ExtendedLayerConfigSplit_ExtendedLayerConfigId', type: 'AllowedValues', allowedValues: matchingExtendedLayerConfigs.map(function (data) { return { type: 'SingletonValue', value: String(data.ExtendedLayerConfigId) }; }) }], false);
                _loop_2 = function (i) {
                    var layer, _b, values_1, variableAssignment, _c;
                    return __generator(this, function (_d) {
                        switch (_d.label) {
                            case 0:
                                layer = layers[i];
                                if (!(layer.variableAssignments && layer.model)) return [3 /*break*/, 4];
                                if (!!layer.section) return [3 /*break*/, 2];
                                _b = layer;
                                return [4 /*yield*/, configureLayer(layer.variableAssignments.filter(function (data) { return data.variableId && data.variableId !== 'ComponentId' && data.variableId !== 'ExtendedLayerConfigSplit_ExtendedLayerConfigId'; }), layer.model)];
                            case 1:
                                _b.section = _d.sent();
                                _d.label = 2;
                            case 2:
                                values_1 = (_layer$section2 = layer.section) === null || _layer$section2 === void 0 ? void 0 : (_layer$section2$varia = _layer$section2.variables) === null || _layer$section2$varia === void 0 ? void 0 : (_layer$section2$varia2 = _layer$section2$varia.find(function (data) { return data.id === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId'; })) === null || _layer$section2$varia2 === void 0 ? void 0 : (_layer$section2$varia3 = _layer$section2$varia2.values) === null || _layer$section2$varia3 === void 0 ? void 0 : _layer$section2$varia3.map(function (data) { return String(data.value); });
                                variableAssignment = __assign({}, newVariableAssignments.find(function (data) { return data.variableId === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId'; }));
                                if (variableAssignment !== null && variableAssignment !== void 0 && variableAssignment.allowedValues) {
                                    variableAssignment.allowedValues = variableAssignment === null || variableAssignment === void 0 ? void 0 : (_variableAssignment$a = variableAssignment.allowedValues) === null || _variableAssignment$a === void 0 ? void 0 : _variableAssignment$a.filter(function (data) { return values_1 === null || values_1 === void 0 ? void 0 : values_1.includes(String(data.value)); });
                                }
                                _c = layer;
                                return [4 /*yield*/, configureLayer(layer.variableAssignments.filter(function (data) { return data.variableId && data.variableId !== 'ComponentId' && data.variableId !== 'ExtendedLayerConfigSplit_ExtendedLayerConfigId'; }).concat(variableAssignment), layer.model)];
                            case 3:
                                _c.section = _d.sent();
                                _d.label = 4;
                            case 4: return [2 /*return*/];
                        }
                    });
                };
                i = 0;
                _a.label = 1;
            case 1:
                if (!(i < layers.length)) return [3 /*break*/, 4];
                return [5 /*yield**/, _loop_2(i)];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3:
                i++;
                return [3 /*break*/, 1];
            case 4:
                relativeSeqNum = 0;
                layerTypeIdVA = [];
                exchangeVariableAssignments = layers.map(function (layer, index) {
                    if (layer.isSecurementLayer)
                        relativeSeqNum++;
                    if (layer.variableAssignments && layer.model) {
                        var _layer$section3, _layer$section3$varia, _layer$section3$varia2, _layer$variableAssign4;
                        var allowedLayerConfigs_1 = (_layer$section3 = layer.section) === null || _layer$section3 === void 0 ? void 0 : (_layer$section3$varia = _layer$section3.variables) === null || _layer$section3$varia === void 0 ? void 0 : (_layer$section3$varia2 = _layer$section3$varia.find(function (data) { return data.id === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId'; })) === null || _layer$section3$varia2 === void 0 ? void 0 : _layer$section3$varia2.values;
                        var extendedLayerConfigIdVA = newVariableAssignments.find(function (data) { return data.variableId === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId'; });
                        if (extendedLayerConfigIdVA !== null && extendedLayerConfigIdVA !== void 0 && extendedLayerConfigIdVA.allowedValues) {
                            extendedLayerConfigIdVA.allowedValues = extendedLayerConfigIdVA.allowedValues.filter(function (data) { var _allowedLayerConfigs$; return !(allowedLayerConfigs_1 !== null && allowedLayerConfigs_1 !== void 0 && (_allowedLayerConfigs$ = allowedLayerConfigs_1.find(function (obj) { return obj.value === data.value; })) !== null && _allowedLayerConfigs$ !== void 0 && _allowedLayerConfigs$.incompatible); });
                        }
                        var allowedValues = (_layer$variableAssign4 = layer.variableAssignments.find(function (data) { return data.variableId === 'ComponentId_view'; })) === null || _layer$variableAssign4 === void 0 ? void 0 : _layer$variableAssign4.allowedValues; // The response of Configit call to specification layer has no "ComponentId_view" variable
                        var componentVariableId_1 = layer.id === '25' || layer.id === '35' ? 'ComponentId' : 'ComponentId_view';
                        if (!allowedValues) {
                            var _layer$section4, _layer$section4$varia, _layer$section4$varia2, _layer$section4$varia3, _layer$section4$varia4;
                            allowedValues = (_layer$section4 = layer.section) === null || _layer$section4 === void 0 ? void 0 : (_layer$section4$varia = _layer$section4.variables) === null || _layer$section4$varia === void 0 ? void 0 : (_layer$section4$varia2 = _layer$section4$varia.find(function (data) { return data.id === componentVariableId_1; })) === null || _layer$section4$varia2 === void 0 ? void 0 : (_layer$section4$varia3 = _layer$section4$varia2.values) === null || _layer$section4$varia3 === void 0 ? void 0 : (_layer$section4$varia4 = _layer$section4$varia3.filter(function (data) { return !data.incompatible; })) === null || _layer$section4$varia4 === void 0 ? void 0 : _layer$section4$varia4.map(function (data) { return { type: data.type, value: data.value }; });
                        }
                        else {
                            var _layer$section5, _layer$section5$varia, _layer$section5$varia2, _layer$section5$varia3, _layer$section5$varia4; // Exclude components that has "incompatible" true in the previous response
                            var allowedValuesFromSection_1 = (_layer$section5 = layer.section) === null || _layer$section5 === void 0 ? void 0 : (_layer$section5$varia = _layer$section5.variables) === null || _layer$section5$varia === void 0 ? void 0 : (_layer$section5$varia2 = _layer$section5$varia.find(function (data) { return data.id === componentVariableId_1; })) === null || _layer$section5$varia2 === void 0 ? void 0 : (_layer$section5$varia3 = _layer$section5$varia2.values) === null || _layer$section5$varia3 === void 0 ? void 0 : (_layer$section5$varia4 = _layer$section5$varia3.filter(function (data) { return !data.incompatible; })) === null || _layer$section5$varia4 === void 0 ? void 0 : _layer$section5$varia4.map(function (data) { return { type: data.type, value: data.value }; });
                            if (allowedValuesFromSection_1) {
                                allowedValues = allowedValues.filter(function (item) { return allowedValuesFromSection_1.find(function (sectionItem) { return sectionItem.value == item.value; }); });
                            }
                        }
                        var layerData = getLayerDataById(layer.id);
                        var layerProps = getLayerProps(layerData);
                        var variableId = "ComponentId_".concat(layer.model);
                        if (layer.isSecurementLayer) {
                            variableId = "ComponentId_GenericSecurement".concat(relativeSeqNum); //variableId = (relativeSeqNum === 9 ? `ComponentId_GenericSecurement9`: `ComponentId_GenericSecurement_${relativeSeqNum}Up`);
                            var securementLayer = layer; // Add LayerTypeId_GenericSecurement[1-9] only when from/to of this securement has been set
                            if (securementLayer.fromLayerTypeId || securementLayer.toLayerTypeId) {
                                layerTypeIdVA.push({ variableId: "LayerTypeId_GenericSecurement".concat(relativeSeqNum), value: layer.id });
                            }
                            ;
                        }
                        else if (layerProps.hasDuplicates) { // add "else " before "if" since securement layer also hasDuplicates. Avoid variableId like "ComponentId_SecurementCover1". Main model only use "ComponentId_GenericSecurement..."
                            var layerRelativeSeqNum = 0;
                            for (var i = 0; i < layers.length; i++) {
                                if (layers[i].id === layer.id)
                                    layerRelativeSeqNum++;
                                if (i === index)
                                    break;
                            }
                            variableId = "ComponentId_".concat(layer.model).concat(layerRelativeSeqNum);
                        }
                        return { variableId: variableId, type: 'AllowedValues', allowedValues: allowedValues };
                    }
                    return {};
                }).filter(function (data) { return data.variableId; });
                // To use securement layer type id, may need to create LayerTypeId_GenericSecurement_1Up, LayerTypeId_GenericSecurement_2Up fields, ...
                // They should be comma delimited layer type ids by concatenation. See ComponentId_GenericSecurement_[1-8]Up for reference
                // Before finish the above implementation, we should not put layerTypeIdVA into request payload
                return [2 /*return*/, newVariableAssignments.concat(exchangeVariableAssignments)]; //return layerTypeIdVA.length > 0 ? newVariableAssignments.concat(exchangeVariableAssignments).concat(layerTypeIdVA) : newVariableAssignments.concat(exchangeVariableAssignments);
        }
    });
}); };
export var updateSelfSecurementFilter = function (layers, filters) {
    // We should not reset _and property to empty array, otherwise it will erase layer search criteria
    //filters._and = [];
    layers.forEach(function (layer) {
        var _layer$selfSecurement; // Only when this layer has self-securement and this self-securement has variableAssignments, filter criteria may be available
        if (!layer.hasSelfSecurement || !layer.selfSecurement || !layer.selfSecurement.variableAssignments)
            return;
        var layerData = getLayerDataById(layer.id);
        var layerProps = getLayerProps(layerData);
        var model = layerProps.model ? layerProps.model : '--NA--';
        var view = LAYERMODELHASURA_MAP[model];
        if (!view)
            return;
        view = view + '_SelfSecurement'; // make the view name is different from component layer view
        var filter = {};
        filter[view] = {};
        var filterApplied = false;
        (_layer$selfSecurement = layer.selfSecurement.variableAssignments) === null || _layer$selfSecurement === void 0 ? void 0 : _layer$selfSecurement.forEach(function (data) { var _layer$selfSecurement2, _layer$selfSecurement3, _layer$selfSecurement4; var variable = (_layer$selfSecurement2 = layer.selfSecurement) === null || _layer$selfSecurement2 === void 0 ? void 0 : (_layer$selfSecurement3 = _layer$selfSecurement2.section) === null || _layer$selfSecurement3 === void 0 ? void 0 : (_layer$selfSecurement4 = _layer$selfSecurement3.variables) === null || _layer$selfSecurement4 === void 0 ? void 0 : _layer$selfSecurement4.find(function (variable) { return variable.id === data.variableId; }); if (variable && variable.id) {
            var _data$allowedValues2;
            var type = variable.properties && variable.properties[0] && variable.properties[0].value;
            if (variable.id === 'ManufacturerId' || variable.id === 'ComponentId' || variable.id === 'ExtendedLayerConfigSplit_ExtendedLayerConfigId' || EXCHANGE_VARIABLES.includes("".concat(variable.id, "_view")) || variable.id.endsWith('Operator'))
                return;
            if (variable.id === 'ComponentId_view')
                filter[view]['ComponentId'] = { _in: (_data$allowedValues2 = data.allowedValues) === null || _data$allowedValues2 === void 0 ? void 0 : _data$allowedValues2.map(function (obj) { return parseInt(String(obj.value)); }) };
            else if (type === 'enum' || type === 'codetable')
                filter[view][variable.id] = { _eq: parseInt(String(data.value)) };
            else if (type === 'decimal')
                filter[view][variable.id] = { _eq: parseFloat(String(data.value)) };
            else if (type === 'measurement') {
                var _layer$selfSecurement5, _layer$selfSecurement6, _layer$selfSecurement7;
                var operator = (_layer$selfSecurement5 = layer.selfSecurement) === null || _layer$selfSecurement5 === void 0 ? void 0 : (_layer$selfSecurement6 = _layer$selfSecurement5.variableAssignments) === null || _layer$selfSecurement6 === void 0 ? void 0 : (_layer$selfSecurement7 = _layer$selfSecurement6.find(function (data) { return data.variableId === "".concat(variable.id, "Operator"); })) === null || _layer$selfSecurement7 === void 0 ? void 0 : _layer$selfSecurement7.value;
                if (!operator)
                    return;
                filter[view][variable.id] = {};
                var PRECISIONTOLERANCE = 0.0001;
                switch (String(operator)) {
                    case 'Equal':
                        filter[view][variable.id]['_gte'] = parseFloat(String(data.value)) - PRECISIONTOLERANCE;
                        filter[view][variable.id]['_lte'] = parseFloat(String(data.value)) + PRECISIONTOLERANCE;
                        break;
                    case 'BiggerOrEqual':
                        filter[view][variable.id][OPERATOR_FILTER_MAP[String(operator)]] = parseFloat(String(data.value)) - PRECISIONTOLERANCE;
                        break;
                    case 'LessOrEqual':
                        filter[view][variable.id][OPERATOR_FILTER_MAP[String(operator)]] = parseFloat(String(data.value)) + PRECISIONTOLERANCE;
                        break;
                    default: filter[view][variable.id][OPERATOR_FILTER_MAP[String(operator)]] = parseFloat(String(data.value));
                }
            }
            else if (type === 'boolean')
                filter[view][variable.id] = { _eq: Boolean(String(data.value)) };
            else
                filter[view][variable.id] = { _eq: data.value };
            filterApplied = true;
        } });
        if (filterApplied)
            filters._and.push(filter);
    });
    return filters;
};
